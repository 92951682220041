











import { Component, Vue } from "vue-property-decorator";
import { db } from "@/scripts/firebase";
import ProfileCard from "@/components/ProfileCard.vue";
import VideoList from "@/components/VideoList.vue";
import Pagination from "@/components/Pagination.vue";

@Component({
  components: {
    ProfileCard,
    VideoList,
    Pagination
  }
})
export default class ModelInfoView extends Vue {
  modelData: any | undefined = {};
  videos: Array<object> = [];

  created() {
    // 女優を検索する時は、コレクションを"models"にする。
    // ドキュメントは女優名で検索する
    const collectionRef = db.collection("models");
    const docRef = collectionRef.doc(this.$route.params.modelName);
    docRef
      .get()
      .then(doc => {
        if (!doc.exists) {
          console.log("No such document!");
        } else {
          console.log("Document data:", doc.data());
          this.modelData = doc.data();
        }
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error);
      });

    const videoCollectionRef = db.collection("videos");
    videoCollectionRef
      .where("model", "==", this.$route.params.modelName)
      .get()
      .then(querySnapshot => {
        querySnapshot.forEach(doc => {
          console.log(doc.id, " => ", doc.data());
          this.videos.push(doc.data());
        });
      })
      .catch(function(error) {
        console.log("Error getting documents: ", error);
      });
  }
}
